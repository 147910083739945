import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Adminsidebar from '../Components/Adminsidebar'
import Nvbar from '../Components/Nvbar'
// import Navbarr from '../Components/Navbarr'

export default function Layout({ logout, userData , isAdmin }) {

  let navto = useNavigate()
  // console.log({ logout, userData })

  return (<>

    <Nvbar logout={logout} userData={userData} isAdmin={isAdmin} />
    <div className='d-flex pt-3'>
      {/* <Adminsidebar /> */}
      <div className='pt-5 col-12'>
        <Outlet />
      </div>
    </div>
  </>
  )
}
