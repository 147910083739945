import React, { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import Loading from './Loading'
import Cartitem from '../Components/Cartitem'

export default function Cart() {
    let navto = useNavigate()
    let [data, setdata] = useState()
    let [user, setuser] = useState()

    const getMyData = async () => {
        let userData = localStorage.getItem('token')
        let { gmail, id } = jwtDecode(userData)
        setuser(id)
        console.log(id)

        let { data } = await axios.get(`https://skincare-92zr.onrender.com/user/by/${id}`).catch(err => { console.log(err) })
        console.log(data.message)
        let myCart = data.message.cart

        setdata(myCart)


    }

    const deleteCartItem = async (userID, props, itemquantity) => {
        let { data } = await axios.patch(`https://skincare-92zr.onrender.com/user/dltfromcart/${userID}/${props._id}`).catch(
            err => {
                console.log(err)
            }
        )
        // console.log(data)
        if (data) {
            let result = await axios.get(`https://skincare-92zr.onrender.com/product/by/${props._id}`).catch(err => console.log(err))
            let quant = result.data.message.quantity
            console.log(quant)
            let x = quant + itemquantity
            console.log(x)
            setdata(undefined)
            let res = await axios.put(`https://skincare-92zr.onrender.com/product/update/${props._id}`, { quantity: x }).catch(
                err => { console.log(err) }
            )
            let cart = data.message.cart
            // console.log(cart)
            setdata(cart)
            // console.log()
        }

    }

    useLayoutEffect(() => {
        getMyData()
    }, [])
    // console.log(data)
    return (
        <div className='container d-flex flex-column pb-5 crt position-relative' >
            <div className='d-flex flex-wrap'>
                {
                    data ? data.length ? <div className='container'>
                        {
                            data.map((ele, i) => {
                                console.log(ele)
                                return <Cartitem props={ele} key={ele._id} userID={user} deleteCartItem={deleteCartItem} />
                            })
                        }
                    </div>
                        :
                        <div className='pt-5 w-100'><div className='w-100 h-100 d-flex justify-content-center align-items-center fw-bold fs-3 alert alert-secondary'>No Items In Cart</div></div>

                        : < Loading />}

            </div>
            {data ? data.length ? <button className='logout-btn rounded rounded-2 border position-absolute start-50 bottom-0 col-12 translate-middle-x' onClick={() => { navto(`/order/${user}`) }}>
                <span className='white-text fs-6 col-12'>Buy</span>
            </button> : <></> : <></>}

            {/* <button className='logout-btn rounded rounded-2 border position-absolute bottom-0'> <span className='white-text fs-6'>Buy</span></button> */}
        </div >
    )
}
