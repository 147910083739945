import React, { useEffect, useState } from 'react'
import Ordercard from '../Components/Ordercard'
import axios from 'axios'
import Loading from './Loading'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { useLayoutEffect } from 'react'

export default function Orders() {
    let [filter, setfilter] = useState('')
    useLayoutEffect(() => {
        let userData = localStorage.getItem('token')
        if (!userData) {
            navto('/login')
        }
    })
    let [data, setdata] = useState()
    let [isAdmin, setAdmin] = useState()
    let [mcart, setcart] = useState()
    let navto = useNavigate()
    let getData = async () => {
        let newdata = []
        let userData = localStorage.getItem('token')
        let { gmail, id } = jwtDecode(userData)
        let result
        if (filter.length > 0) {
            let { data } = await axios.get(`https://skincare-92zr.onrender.com/order/by?state=${filter}`)
            result = data.message.reverse()
        } else {
            let { data } = await axios.get(`https://skincare-92zr.onrender.com/order/all`)
            result = data.message.reverse()
        }
        if (gmail == "careskin404@gmail.com") {
            // get all orders
            setAdmin(true)
            newdata = [...result]
            let cart = []
            newdata.map(ele => {
                ele.cart.map(async (el) => {
                    // console.log(el.product)
                    let d = await axios.get(`https://skincare-92zr.onrender.com/product/by/${el.product}`).catch(err => console.log(err))
                    cart.push(d.data.message)
                })
            })
            newdata['cart'] = cart
            // console.log("hiii",cart)
        } else {
            setAdmin(false)
            //get user order
            newdata = result.filter(ele => ele.user == id)
        }
        // console.log(newdata)
        setdata(newdata)
    }
    useEffect(() => {
        getData()
    }, [filter])
    useEffect(() => {
        getData()
    }, [])
    const deleteOrderItem = async (orderID, props, itemquantity) => {
        let { data } = await axios.delete(`https://skincare-92zr.onrender.com/order/dltall/${orderID}`).catch(
            err => {
                console.log(err)
            }
        )
        // console.log(data)
        if (data) {
            setdata(undefined)
            getData()
        }

    }
    return (

        <div className='pt-2 container'>
            <div className='d-flex justify-content-center align-items-center py-3 '>
                <div className='lineb'></div>
                <h2 className='fw-thin fs-6 mx-1 m-0'>{isAdmin? 'Admin' : 'My'} Orders</h2>
                <div className='lineb'>

                </div>
            </div>
            <div className='mb-5'>
                <select className="form-select form-control w-100" onChange={(e) => {setdata(undefined); setfilter(e.target.value) }}>
                    <option value=''>Without filter</option>
                    <option value="pending">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="ready to ship">Ready to ship</option>
                    <option value="shipping">Shipped</option>
                    <option value="canceled">Canceled</option>
                    <option value="delivered">delivered</option>
                    <option value="done">Done</option>
                </select>
            </div>
            {data ? data.length ? data.map((ele, i) => {
                return <Ordercard key={ele._id} isAdmin={isAdmin} data={ele} deleteOrderItem={deleteOrderItem}/>
            }) : <div className='pt-5 w-100'><div className='w-100 h-100 d-flex justify-content-center align-items-center fw-bold fs-3 alert alert-secondary'>No Orders</div></div>
                : <Loading />}
        </div>

    )
}
