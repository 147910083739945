import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';

export default function Nvbar({ logout, userData, isAdmin }) {
  let navto = useNavigate()
  let [login, setlogin] = useState(false)
  const [expanded, setExpanded] = useState(false);

  const handleSelect = () => {
    setExpanded(false);
  };



  const check = () => {
    if (userData) {
      setlogin(true)
    } else {
      setlogin(false)
    }
  }
  useEffect(() => {
    check()
    // console.log(isAdmin)
  }, [])
  useEffect(() => {
    check()
    // console.log(isAdmin)
  }, [userData])

  // console.log(login)
  return (
    <Navbar expand="lg" className="bg-body-tertiary position-fixed w-100 navv py-1 fw-thin fs-6 fs-xs-6 py-md-0" expanded={expanded}>
      <Container>
        <Navbar.Brand onClick={() => {setExpanded(false); navto('/') }}><span className='logo pink-text fw-light  make-cursor fs-3'><span className='fw-bold fs-1 k'>K</span>beautyMall</span></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"  onClick={() => setExpanded(expanded ? false : "expanded")}/>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" onSelect={handleSelect}>
            <div><Nav.Link onClick={() => {setExpanded(false); navto('/') }} className='fw-bold'>Home</Nav.Link></div>
            <Nav.Link onClick={() => {setExpanded(false); navto('/all') }} className='fw-bold'>Products</Nav.Link>
            {isAdmin ? <Nav.Link onClick={() => { setExpanded(false);navto('/prodForm') }} className='fw-bold'> Add new product</Nav.Link> : <></>}
            <NavDropdown title="Categories" id="basic-nav-dropdown" className='fw-bold' >

              <NavDropdown.Item onClick={() => { setExpanded(false); navto('/all/?category=Toners') }}>
                Essence & Toner
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => {setExpanded(false);  navto('/all/?category=Serums') }}>Serums</NavDropdown.Item>

              <NavDropdown.Divider />

              <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all/?category=EyeCare') }}>
                Eye Care
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all/?category=Cleansers') }}>Mask & Cleanser</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all/?category=Moisturizers') }}>
                Moisturizer
              </NavDropdown.Item>
              <NavDropdown.Divider />

              <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all/?category=Sunscreens') }}>
                Sunscreen
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all/?category=LipCare') }}>
                Lip Care
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all') }}>
                <span className='text-danger'>Show all</span>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Brands" id="dropdown-autoclose-true" className='fw-bold' >
              <div className='mx-hi'>
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Abib') }}>
                  Abib
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Anua') }}>
                  Anua
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=A.H.C') }}>
                  A.H.C
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => { setExpanded(false);navto('/all?brand=Axis_y') }}>
                  Axis-y

                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => { setExpanded(false);navto('/all?brand=Beauty_of_joseon') }}>
                  Beauty Of joseon

                </NavDropdown.Item><NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=BELLAMONSTER') }}>
                  BELLAMONSTER

                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=B.LAB') }}>B.LAB</NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Cosrx') }}>Cosrx</NavDropdown.Item>
                <NavDropdown.Divider />


                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=COSNORI') }}>COSNORI</NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Dr.Ceuracle') }}>Dr.Ceuracle</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=DEWYTREE') }}>DEWYTREE</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=esfolio') }}>
                  esfolio
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Frudia') }}>
                  Frudia
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => { setExpanded(false); navto('/all?brand=Goodal') }}>
                  Goodal
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Hada_Labo') }}>
                  Hada Labo
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=heimish') }}>
                  heimish
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Haruharu') }}>
                  Haruharu
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Holika_holika') }}>
                  Holika holika
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => { setExpanded(false); navto('/all?brand=innisfree') }}>Innisfree</NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => { setExpanded(false); navto('/all?brand=Im_form') }}>
                  Im form
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=IUNIK') }}>
                  IUNIK
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Isntree') }}>
                  Isntree
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Jumiso') }}>
                  Jumiso
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Klavuu') }}>
                  Klavuu
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Klairs') }}>
                  Klairs
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Kose') }}>
                  Kose
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Ksecret') }}>
                  Ksecret
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Kao-Biore') }}>
                  Kao-Biore
                </NavDropdown.Item>


                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Manyo_factory') }}>
                  Manyo Factory
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Mary_and_May') }}>
                  Mary and May
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Missha') }}>
                  Missha
                </NavDropdown.Item>

                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Neogen') }}>
                  Neogen
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Numbuzin') }}>
                  Numbuzin
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Purito') }}>
                  Purito
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Peach_C') }}>
                  Peach C
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Pyunkang_yul') }}>
                  Pyunkang yul
                </NavDropdown.Item>
                <NavDropdown.Divider />


                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=ROUND_LAB') }}>
                  ROUND LAB
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Rovectin') }}>
                  Rovectin
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=SCINIC') }}>
                  SCINIC
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Skinfood') }}>
                  Skinfood
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=SKIN1004') }}>
                  SKIN1004
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=SOME_BY_MI') }}>
                  SOME BY MI
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => { setExpanded(false);navto('/all?brand=Torriden') }}>
                  Torriden
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => { setExpanded(false);navto('/all?brand=Tosowoong') }}>
                  Tosowoong
                </NavDropdown.Item>

                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=THE_FACE_SHOP') }}>
                  THE FACE SHOP
                </NavDropdown.Item>

                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=TOCOBO') }}>
                  TOCOBO
                </NavDropdown.Item>

                <NavDropdown.Divider />



                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=VT_cometics') }}>
                  VT cometics
                </NavDropdown.Item>
                <NavDropdown.Divider />



                <NavDropdown.Item onClick={() => {setExpanded(false); navto('/all?brand=Wishtrend') }}>
                  Wishtrend
                </NavDropdown.Item>


                <NavDropdown.Divider />





                <NavDropdown.Item onClick={() => {setExpanded(false);  navto('/all') }}>
                  <span className='text-danger'>Show All</span>
                </NavDropdown.Item>
              </div>
            </NavDropdown>
          {login? <><Nav.Link className='fw-bold' onClick={()=>{setExpanded(false); navto('/wish')}}><i class="fa-solid fa-heart me-1"></i><span>My Wishlist</span></Nav.Link>
            
            
            <Nav.Link className='fw-bold'  onClick={()=>{setExpanded(false); navto('/cart')}}><i class="fa-solid fa-cart-shopping me-1"></i><span>My Cart</span></Nav.Link>
            <Nav.Link className='fw-bold'  onClick={()=>{setExpanded(false); navto('/myorders')}}><i class="fa-solid fa-bag-shopping me-1"></i><span>My Orders</span></Nav.Link>
            
            </>  :<></>
          
          
          }</Nav>
          {login ? <button className='logout-btn py-2 px-5 px-1 border border-1  d-flex align-items-center justify-content-around rounded rounded-2' onClick={() => {setExpanded(false); logout() ; navto('/login') }}><span className='white-text'>logout</span> </button>
            : <button className='product-btn py-2 px-5 px-1 border border-1  d-flex align-items-center justify-content-around rounded rounded-2' onClick={() => {setExpanded(false); navto('/login') }}><span className='pink-text'>login</span> </button>
          }
        </Navbar.Collapse>

      </Container>
    </Navbar>
  )
}
