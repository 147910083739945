import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Login({ setlogin ,setuser}) {
    let navto = useNavigate()
    let [data, setdata] = useState({})
    let [errors, seterror] = useState()
    let [loading, setloading] = useState(false)
    
    let handlesubmition = (e) => {
        let newData = { ...data }
        newData[e.target.name] = e.target.value
        setdata(newData)
    }
    let submit = async (e) => {
        e.preventDefault()
        setloading(true)
        let done = await axios.post("https://skincare-92zr.onrender.com/user/login", data).catch(
            err => {
                console.log(err.response.data.err);
                seterror(err.response.data.err)
            }
        )
        setloading(false)
        if (done) {
            console.log(done.data.message.token)
            localStorage.setItem("token", done.data.message.token)
            setlogin(true)
            setuser(done.data.message.token)
            navto('/')
        }
    }
    return (
        <form className='mt-5 p-4' onSubmit={(e) => { submit(e) }}>
            <h1 className='pink-text'>Login</h1>
            {errors ? <div className='alert alert-danger'>
                Invalid Email or password
            </div> : <></>}
            <div className='d-flex flex-wrap justify-content-center'>
                <div className='col-md-6 col-sm-12 p-2'>
                    <label htmlFor="gmail">Email</label>
                    <input type="email" name="gmail" id="gmail" placeholder='example@gmail.com' className='form-control' onChange={(e) => { handlesubmition(e) }} />
                </div>
                <div className='col-md-6 col-sm-12 p-2'>
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" placeholder='Password' className='form-control' onChange={(e) => { handlesubmition(e) }} />
                </div>
            </div>
            <div className='w-100 text-center'>
                <p>don't have account? <span className='text-primary make-cursor' onClick={() => { navto('/signup') }}>create account</span></p>
            </div>
            {/* <div className='w-100 text-center p-1'><button className=' btn border product-btn '>  <span className=' pink-text'>Login</span></button></div> */}
            <div className='w-100 text-center p-1'><button className=' btn border product-btn ' onSubmit={(e) => { submit(e) }}> {loading ? <span><i class="fa-solid fa-spinner fa-spin pink-text"></i></span> : <span className=' pink-text'>Login</span>}</button></div>

        </form>
    )
}
