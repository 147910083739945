import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from './Loading'
import Cartitem from '../Components/Cartitem'

export default function Order() {

    let navto = useNavigate()
    let { id } = useParams()
    let [data, setdata] = useState()
    let [orderData, setorderData] = useState()

    let [user, setuser] = useState()
    let [myCart, setmyCart] = useState([])
    // let [orderErr, setOrderErr] = useState(false)
    let [total, settotal] = useState()
    let [loading, setLoading] = useState(false)
    let [error, setError] = useState()
    const handleSubmition = (e) => {
        // let data = e.target.name
        let newdata = { ...data }
        newdata[`${e.target.name}`] = e.target.value

        setdata(newdata)
        console.log(newdata)
    }

    const cities = [
        "6th of October City",
        "Alexandria",
        "Aswan",
        "Asyut",
        "Banha",
        "Beni Suef",
        "Cairo",
        "Damanhur",
        "Damietta",
        "El-Mahalla El-Kubra",
        "Faiyum",
        "Giza",
        "Hurghada",
        "Ismailia",
        "Kafr el-Sheikh",
        "Luxor",
        "Mansoura",
        "Minya",
        "New Cairo",
        "Port Said",
        "Qena",
        "Sharm El Sheikh",
        "Sohag",
        "Suez",
        "Tanta",
        "Zagazig"
    ];

    const submition = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(false)
        let newcart = []
        let cart = orderData
        cart.map(el => newcart.push({ product: el._id, quantity: el.quantity, price: el.price }))
        console.log(orderData)
        let finalData = { ...data }
        if (finalData['mobileNo']?.length >= 11 && finalData['location']?.length > 15) {
            setError(false)
            finalData['cart'] = newcart
            document.getElementById('btn-sub').disabled = true;
            await axios.post(`https://skincare-92zr.onrender.com/order/add/${id}`, finalData).then(
                (res) => {
                    return axios.patch(`https://skincare-92zr.onrender.com/user/dltcart/${id}`).then(
                        (res) => {
                            return navto(`/myorders`)
                        }
                    ).catch(err => setError("Sorry, Error in deleting items of cart"))
                }
            ).catch(err => setError("Sorry, Error in Order Confirmation, Please Check Internet Connection"))
            setLoading(false)

        } else {
            setError('Sorry we need valid mobile number and location')
            setLoading(false)
        }
        document.getElementById('btn-sub').disabled = false;
    }

    const getMyData = async (id) => {
        setuser(id)
        let { data } = await axios.get(`https://skincare-92zr.onrender.com/user/by/${id}`).catch(err => { console.log(err) })
        console.log(data.message.cart)
        setmyCart(data.message.cart)

    }
    const deleteCartItem = async (userID, props, itemquantity) => {

    }
    let calculate = async (myCart) => {
        total = 0
        const updatedCart = []
        console.log(myCart)
        for (const ele of myCart) {
            console.log(ele)
            let t = 0
            const { data } = await axios.get(`https://skincare-92zr.onrender.com/product/by/${ele._id}`).catch(err => { console.log(err) })
            t = data.message.price * ele.quantity
            total += t
            console.log(t)
            console.log(total)
            updatedCart.push({ ...ele, price: data.message.price })
        }
        settotal(total)
        setorderData(updatedCart)
    }
    useEffect(() => {
        calculate(myCart)
    }, [myCart])
    useEffect(() => {
        getMyData(id)
    }, [])
    return (
        <div className='container'>
            {orderData && myCart.length > 0 ? myCart.length ?
                <div className=''>
                    <div className='ordercart overflow-y-scroll border '>
                        <button className='logout-btn rounded rounded-2 border col-12 p-2' onClick={() => { navto('/cart') }}> <span className='white-text'>Edit my cart</span>
                        </button>
                        {orderData.map(ele => {
                            return <Cartitem props={ele} key={ele} userID={ele._id} deleteCartItem={deleteCartItem} order={true} />
                        })}



                    </div>

                    <div className='col-12 p-3'>
                        <form action="" onSubmit={(e) => { submition(e) }}>
                            <div className='d-flex justify-content-center align-items-center py-3 '>
                                <div className='lineb'></div>
                                <h2 className='fw-thin fs-6 mx-1 m-0'>Information</h2>
                                <div className='lineb'>

                                </div>
                            </div>
                            {error ? <div className='alert alert-danger'>
                                {error}
                            </div> : <></>}

                            <div>
                                <div className='col-md-6 col-sm-12 col-xs-12 my-2'>
                                    <p className='price'>Total Price : {total ? total : "Loading"} EGP</p>
                                </div>
                                <div className='col-md-6 col-sm-12 col-xs-12 my-2'>
                                    <label htmlFor="mobileNo" className=' fs-6 fs-xs-6'>Mobile Number *</label>
                                    <input type="text" name="mobileNo" onChange={(e) => { handleSubmition(e) }} id="mobileNo" placeholder='Mobile number' className='form-control' />
                                </div>

                                <div className='col-md-6 col-sm-12 col-xs-12 my-2'>
                                    <label htmlFor="location " className='fs-6 fs-xs-6'>Location *</label>
                                    <input type="text" name="location" id="location" onChange={(e) => { handleSubmition(e) }} placeholder='location , city , country' className='form-control' />
                                </div>
                                <div className='col-md-6 col-sm-12 col-xs-12 my-2'>
                                    <label htmlFor="userNote " className='fs-6 fs-xs-6'>Note</label>
                                    <textarea rows={4} name="userNote" id="userNote" onChange={(e) => { handleSubmition(e) }} placeholder='note...' className='form-control' />
                                </div>
                            </div>
                            <button className='logout-btn rounded rounded-2 border col-12 my-2 p-2' id='btn-sub' onClick={() => { setLoading(true) }} >
                                {loading ? <span><i class="fa-solid fa-spinner fa-spin fs-6 fs-xs-6 white-text"></i></span> : <span className='white-text fs-6 fs-xs-6'>Confirm Order</span>}
                            </button>

                        </form>
                    </div>
                </div>
                : <div className='pt-5 w-100'><div className='w-100 h-100 d-flex justify-content-center align-items-center fw-bold fs-3 alert alert-secondary'>No Items In Cart</div></div>
                : <Loading />}
        </div>
    )
}
