import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Cartitem from './Cartitem';
import jwtDecode from 'jwt-decode';
import { useLayoutEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
export default function Ordercard({ isAdmin, data, deleteOrderItem }) {
    let [state, setstate] = useState('pending')
    let [load, setload] = useState(false)

    let [pdata, setdata] = useState(data)

    let [userData, setUserData] = useState()
    const getUser = async (user) => {
        let { data } = await axios.get(`https://skincare-92zr.onrender.com/user/by/${user}`)
        if (data) {
            // console.log(data.message)
            // setUserData(data.message)
            let name
            let fisrtname = data.message.firstName
            let lastname = data.message.lastName
            if (fisrtname && lastname) {
                name = fisrtname + " " + lastname
            } else if (fisrtname) {
                name = fisrtname
            } else if (lastname) {
                name = lastname
            } else {
                name = "unkown"
            }
            setUserData(name)
        }

    }
    useEffect(() => {
        // console.log(data.createdAt)
        getUser(data.user)
        // console.log("anaa el user data ", userdata)
        let newData = { ...data }
        if (data.createdAt) {
            const dateObj = new Date(data.createdAt);
            let readableDate = dateObj.toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZoneName: 'short',
                timeZone: 'Africa/Cairo'
            });
            readableDate = readableDate.replace('GMT+3', '')
            newData['date'] = readableDate
            // console.log(readableDate)
            // console.log(newData)
            setdata(newData)
        }
        else {
            newData['date'] = 'July 25, 2023 at 12:46:14 PM'
            // console.log(newData)
            setdata(newData)
        }

    }, [])

    let { id } = jwtDecode(localStorage.getItem('token'))
    const deleteCartItem = () => {
    }
    const handleSubmition = (e) => {
        setstate(e.target.value)
    }
    const submition = async () => {
        setload(true)
        // console.log(pdata._id)
        await axios.patch(`https://skincare-92zr.onrender.com/order/update/${pdata._id}`, { state }).then(res => {
            let { data } = res
            // console.log(data.message.state)
            let newdata = { ...pdata }
            newdata['state'] = data.message.state
            setdata(newdata)
            setload(false)
        }).catch(err => {
            setload(false)
            console.log(err)
        })
        setload(false)

    }

    return (
        <>
            <Accordion>
                <Accordion.Item eventKey={pdata._id}>
                    <Accordion.Header >
                        <div className='position-relative w-100'>
                            {isAdmin ? <Popup trigger=
                                {<div className='position-absolute top-0 start-100 p-1'>
                                    <i class="fa-solid fa-trash-can text-danger"></i>
                                </div>}
                                modal nested>
                                {
                                    close => (
                                        <div className='alert alert-danger h-100'><p>Order will deleted for User too<span className='fw-bold'>User</span>  Also  </p>
                                            <div className='d-flex w-100 justify-content-center'>
                                                <button className='btn btn-danger me-2' onClick={(e) => { e.stopPropagation(); deleteOrderItem(pdata._id); close() }}>Delete</button>
                                                <button className='btn btn-light' onClick={() => { close() }}>Cancel</button>
                                            </div>
                                        </div>
                                    )
                                }
                            </Popup> : <></>}
                            <div className='w-100 d-flex flex-wrap'>
                                {pdata.userNote != 'No Notes' ? <div className='col-12 my-1 alert alert-danger'> <span className='fw-bold  my-1'>Note</span>: {pdata.userNote}</div> : <></>}
                                <span className=' my-1 col-12 '> <span className='fw-bold'>Name</span> : {userData ? userData : ""} </span>
                                <span className=' my-1 col-12 '><span className='fw-bold'>Mobile no.</span> : {data.mobileNo} </span>
                                <span className='col-12 my-1'> <span className='fw-bold '>Date </span>: {pdata.date}</span>
                                {pdata.state == 'done' || pdata.state == 'shipping' || pdata.state == 'accepted' || pdata.state == 'delivered'
                                    ?
                                    <span className='  my-1 text-success fw-bold price col-12'>{pdata.state == 'shipping' ? 'Shipped' : pdata.state.charAt(0).toUpperCase() + pdata.state.slice(1)}</span>
                                    : pdata.state == 'canceled'
                                        ? <span className='text-danger col-12 fw-bold price  my-1'>{pdata.state.charAt(0).toUpperCase() + pdata.state.slice(1)}</span>
                                        : pdata.state == 'ready to ship'
                                            ? <span className='ready col-12 fw-bold price  my-1'>{pdata.state.charAt(0).toUpperCase() + pdata.state.slice(1)}</span>
                                            : <span className='pink-text col-12 fw-bold price  my-1'>{pdata.state.charAt(0).toUpperCase() + pdata.state.slice(1)}</span>}
                            </div></div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='col-12 p-2 '>
                            <div className='col-12 d-flex flex-column card-bg p-2 rounded rounded-3 '>

                                <div className='col-10 col-md-11 ps-2 '>
                                    <p className='pink-text m-0 fw-bold my-1 price fs-6 fs-xs-6'>Order ID : <span className='text-dark fw-light price '><small className='price'>{data._id}</small></span></p>
                                    <p className='pink-text m-0 fw-bold my-1 price fs-6 fs-xs-6'>Total price : <span className='text-dark fw-light price '>{data.totalPrice} EGP</span></p>
                                    <p className='pink-text m-0 fw-bold my-1 price fs-6 fs-xs-6'>Mobile number : <span className='text-dark fw-light price'>{data.mobileNo}</span></p>
                                    <p className='pink-text m-0 fw-bold my-1 price fs-6 fs-xs-6'>Location : <span className='text-dark fw-light price'>{data.location}</span></p>

                                    <div className='col-12'>
                                        {
                                            data ? data.cart.map((ele, i) => {
                                                return <div className='col-12 p-1 border'><Cartitem props={ele} key={ele._id} userID={id} deleteCartItem={deleteCartItem} order={true} /></div>
                                            }) : <></>

                                        }
                                    </div>
                                    <p className='pink-text m-0 fw-bold m-0'>State : {isAdmin ? <></> : <span className='text-dark fw-light'>{data.state}</span>}
                                    </p>

                                    {isAdmin ? <> <select class="form-select" onChange={(e) => { handleSubmition(e) }}>
                                        <option value="pending">Pending</option>
                                        <option value="accepted">Accepted</option>
                                        <option value="ready to ship">Ready to ship</option>
                                        <option value="shipping">Shipped</option>
                                        <option value="delivered">delivered</option>
                                        <option value="canceled">Canceled</option>
                                        <option value="done">Done</option>

                                    </select>
                                        <div className='col-12 d-flex justify-content-end p-2'>

                                            <button className='logout-btn rounded rounded-2 border  ms-1 fs-xs-6 py-1' onClick={() => { submition() }}><span className='white-text'>save </span>{load ? <i class="fa-solid fa-spinner fa-spin fs-xs-6 white-text"></i> : <i class="fa-solid fa-check fs-xs-6 white-text"></i>}</button>



                                        </div></>
                                        : <></>}


                                </div>
                                {/* <div className=' p-2 d-flex align-items-center justify-content-center make-cursor '>
                                    <i class="fa-solid fa-chevron-right"></i>
                                </div> */}
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>

    )
}
