import logo from './logo.svg';
import './App.css';
import Productcard from './Components/Productcard';
import Products from './Pages/Products';
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import Layout from './Pages/Layout';
import Addproduct from './Pages/Addproduct';
import Productdetails from './Pages/Productdetails';
import Updateproduct from './Pages/Updateproduct';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import Orders from './Pages/Orders';
import Main from './Pages/Main';
import Filtered from './Pages/Filtered';
import Wishlist from './Pages/Wishlist';
import Cart from './Pages/Cart';
import axios from 'axios';
import Newarrival from './Pages/Newarrival';
import Order from './Pages/Order';

function App() {
  let [login, setlogin] = useState(false)
  let [user, setuser] = useState()
  let [isAdmin, setisadmin] = useState(false)
  const [id, setid] = useState()
  let logout = () => {
    localStorage.removeItem("token")
    setlogin(false)
    setuser(undefined)
    setisadmin(false)
    // console.log(login)
  }
  const checkAdmin = () => {
    if (user) {
      let { gmail , id } = jwtDecode(user)
      // console.log(id)
      setid(id)
      // console.log(gmail)
      if (gmail == "careskin404@gmail.com") {
        setisadmin(true)
      } else {
        setisadmin(false)
      }
    } else {
      setisadmin(false)
    }
  }
  useEffect(() => {
    checkLogin()
    checkAdmin()

  }, [])

  useEffect(() => {
    checkAdmin()
  }, [user])
  useEffect(() => {
    checkLogin()
    checkAdmin()
    // console.log(isAdmin)
  }, [login])
  const checkLogin = () => {
    let tok = localStorage.getItem("token")
    // console.log(tok)
    if (tok) {
      setlogin(true)
      setuser(tok)
    } else {
      setlogin(false)
      setuser(undefined)
    }
  }



  let routers = createHashRouter(
    [
      {
        path: '/', element: <Layout logout={logout} userData={user} isAdmin={isAdmin} />, children: [
          { path: '/', element: <Main /> },
          { path: '/all', element: <Products isAdmin={isAdmin} /> },
          { path: '/orders', element: <Orders isAdmin={isAdmin} userData={user} /> },
          { path: '/prodForm', element: <Addproduct /> },
          { path: "/prod/:id", element: <Productdetails /> },
          { path: '/prodForm/update/:id', element: <Updateproduct /> },
          { path: '/login', element: <Login setlogin={setlogin} setuser={setuser} /> },
          { path: '/signup', element: <Signup /> },
          { path: '/wish', element: <Wishlist userData={user} /> },
          { path: '/cart', element: <Cart /> },
          { path: '/new', element: <Newarrival isAdmin={isAdmin} /> },
          { path: '/order/:id', element: <Order /> },
          { path: '/myorders', element: <Orders isAdmin={isAdmin} userData={id} /> }
        ]
      }
    ]
  )
  return (
    <RouterProvider router={routers} />
  );
}

export default App;
