import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Productcard from '../Components/Productcard'
import Dropdownbox from '../Components/Dropdownbox';
import Sidebar from '../Components/Adminsidebar';

import { useLocation, useNavigate } from 'react-router-dom';
import Loading from './Loading';


export default function Products({ isAdmin }) {
    let [products, setProducts] = useState()
    let [count, setCount] = useState(1)
    let [showed, setShowed] = useState([])
    let [btn, setbtn] = useState(false)
    let [btnload, setbtnload] = useState(false)
    let [params, setParams] = useState(null)
    let [filter, setfilter] = useState(null)
    const location = useLocation()
    const deleteWish = async (userID, props) => {
        let { data } = await axios.patch(`https://skincare-92zr.onrender.com/user/dltfromwishlist/${userID}/${props}`).catch(
            err => {
                console.log(err)
            }
        )
        // console.log(data)
        if (data) {
            const queryParams = new URLSearchParams(location.search)
            let k, v;
            for (const [key, value] of queryParams) {
                if (key === "category" || key === "brand") {
                    k = key; v = value
                    break;
                }
            }
            if (k && v) {
                // console.log("1", k, v)
                getData(k, v)
            }
        }
    }
    let countshowedProduct = (prods = [], count = 1, show = []) => {
        const number = 16
        setbtn(true)
        let diff = (prods.length) - (show.length)
        // console.log("siff", diff)
        let result = []
        if (diff >= number) {
            result = prods.slice(0, (count * number))
        } else {
            result = [...prods]
            setbtn(false)
        }
        return result
    }
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        let k, v;
        for (const [key, value] of queryParams) {
            if (key === "category" || key === "brand") {
                k = key; v = value
                break;
            }
        }
        if (k && v) {
            // console.log("1", k, v)
            getData(k, v)
        }
        // setParams(k);
        // setfilter(v);
    }, [])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let key, value;
        for (const [k, v] of queryParams) {
            if (k === "category" || k === "brand") {
                key = k;
                value = v;
                break;
            }
        }
        // console.log("2", key, value)
        if (key && value) {
            // setParams(key);
            // setfilter(value);
            getData(key, value); // Call getData with the filter value
        } else {
            // setParams(null);
            // setfilter(null);
            getData(key, value); // Call getData without any filter
        }

        setbtnload(false)
    }, [count])
    useEffect(() => {
        setProducts(undefined)
        setCount(1)
        const queryParams = new URLSearchParams(location.search);
        let key, value;
        for (const [k, v] of queryParams) {
            if (k === "category" || k === "brand") {
                key = k;
                value = v;
                break;
            }
        }
        // console.log("2", key, value)
        if (key && value) {
            // setParams(key);
            // setfilter(value);
            getData(key, value); // Call getData with the filter value
        } else {
            // setParams(null);
            // setfilter(null);
            getData(key, value); // Call getData without any filter
        }

    }, [location])

    // useEffect(() => {
    //     getData(filter)
    // }, [filter])
    const getData = async (para, filter) => {
        
        setParams(para)
        let f = filter
        if (f == 'Toners') {
            f = "Essence & Toner"
        } else if (f == 'Cleansers') {
            f = "Mask & Cleanser"
        }
        setfilter(f)
        // console.log('ana bs1l 3al filter', filter, para)
        if (filter) {
            let dta

            let { data } = await axios.get(`https://skincare-92zr.onrender.com/product/all/?${para}=${filter}`).catch(
                err => {
                    console.log(err)
                }
            )

            dta = data.message.reverse()
            setProducts(dta)
            let finalData = countshowedProduct(dta, count, showed)
            // console.log(finalData)
            setShowed(finalData)

        } else {
            let { data } = await axios.get("https://skincare-92zr.onrender.com/product/all").catch(
                err => {
                    console.log(err)
                }
            )
            let dta = data.message.reverse()
            setProducts(dta)
            let finalData = countshowedProduct(dta, count, showed)
            // console.log(finalData)
            setShowed(finalData)

        }
    }

    const getSearchData = async (e) => {
        console.log(e.target.value)
        if (e.target.value == '') {
            const queryParams = new URLSearchParams(location.search);
            let key, value;
            for (const [k, v] of queryParams) {
                if (k === "category" || k === "brand") {
                    key = k;
                    value = v;
                    break;
                }
            }
            // console.log("2", key, value)
            if (key && value) {
                // setParams(key);
                // setfilter(value);
                getData(key, value); // Call getData with the filter value
            } else {
                // setParams(null);
                // setfilter(null);
                getData(key, value); // Call getData without any filter
            }
        }else{
         let { data } = await axios.get(`https://skincare-92zr.onrender.com/product/search?name=${e.target.value}`)
        setProducts(data.message)
        let finalData = countshowedProduct(data.message, count, showed)
        setShowed(finalData)
        }


    }

    const deleteProduct = async (e) => {
        let dlt = await axios.delete(`https://skincare-92zr.onrender.com/product/dlt/${e.target.id}`).catch(
            err => {
                console.log(err)
            }
        )
        let data = await axios.delete(`https://skincare-92zr.onrender.com/new/dlt/${e.target.id}`).catch(
            err => { console.log(err) }
        )
        getData()

    }
    return (<div className='container py-2 pt-3'>

        {filter ? <></> : <div className='d-flex align-items-stretch'>
            <input type="text" className=' form-control border ' placeholder='Search ' onKeyUp={(e) => { getSearchData(e) }} />
            {/* <button className='bg-pink broder border-0 rounded rounded-1' ><i class="fa-solid fa-magnifying-glass"></i></button> */}
            {/* <div>
                <Dropdownbox />
            </div> */}
        </div>}

        <div className='d-flex justify-content-center align-items-center py-3 '>
            <div className='lineb'></div>
            <h2 className='fw-thin fs-6 mx-1 m-0'>{filter ? filter : "Products"}</h2>
            <div className='lineb'>

            </div>
        </div>

        <div className='d-flex justify-content-start align-items-stretch flex-wrap'>
            {products ? <>{products.length ? showed.map((ele) => {
                return Object.keys(ele).length === 0 && ele.constructor === Object ? <></> : <Productcard props={ele} key={ele._id} func={deleteProduct} isAdmin={isAdmin} deleteWish={deleteWish} />
            }) : <div className='w-100 h-100 d-flex justify-content-center align-items-center fw-bold fs-3 alert alert-secondary'>No Available products</div>}</> : <Loading />}
        </div>
        {products && btn ? <div className='d-flex align-items-center justify-content-center w-100'>
            <button className='btn py-3 px-5 px-1 border border-0 rounded rounded-2 d-flex align-items-center' onClick={() => { setbtnload(true); let c = count + 1; setCount(c); }}><span className='pink-text  fw-thin'>more products</span>{btnload?<i class="fa-solid fa-spinner fa-spin pink-text ms-2"></i>:<i class="fa-solid fa-arrow-right pink-text ms-2  fw-thin"></i>} </button>
        </div> : <></>}
    </div>

    )
}
