import axios from 'axios'
import React, { useLayoutEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
export default function Starrating({ prod, uid, rate ,size }) {
    // Catch Rating value
    let [rating, setrating] = useState(0)
    let navto=useNavigate()
    useLayoutEffect(() => {
        console.log(prod,uid,rate,size)
        rate ? setrating(rate) : setrating(0)
        // console.log(rating)
    }, [])
    const handleRating = async (rate) => {
        console.log(rate)
        if(uid){
        axios.put(`https://skincare-92zr.onrender.com/product/rate/${uid}/${prod}`, { rate }).then(
            res => { console.log(res) }
        ).catch(
            err => { console.log(err) }
        )}else{
            navto('/login')
        }
        // other logic
    }
    // Optinal callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value, index) => console.log(value, index)

    return (
        <div className='App '>
            <Rating
                size={size}
                initialValue={rate}
                // totalStars={3}
                onClick={handleRating}
            />
        </div>
    )
}
