import React, { useLayoutEffect, useState } from 'react'
// import img from '../vlcsnap-2023-02-04-13h35m29s448.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Starrating from './Starrating';
import jwtDecode from 'jwt-decode';
export default function Productcard({ props, func, isAdmin, deleteWish }) {
    const navto = useNavigate()
    let d = localStorage.getItem('token')
    // console.log(props)
    let id
    if (d) {
        let dd = jwtDecode(localStorage.getItem('token'))
        id = dd.id
    }

    // console.log(id)
    let [userid, setid] = useState()
    let [quantitywarn, setquanwarn] = useState(false)
    let [wished, setwished] = useState(false)
    let [rating, setrating] = useState()
    let [Connection, setconn] = useState(false)
    let [out, setout] = useState(false)
    let [load, setload] = useState(false)
    let [warn, setwarn] = useState(false)
    let [quan, setquan] = useState(1)
    let [New, setNew] = useState(false)
    let [rate, setRate] = useState()
    let brandname = props.brand.replace("_", " ")

    const inc = () => {

        let q = quan
        q = q + 1
        if (q <= props.quantity) {
            // console.log(props.quantity)
            setquan(q)
        }
        else {
            setwarn(true)
        }
    }
    const dec = () => {
        setwarn(false)
        let q = quan
        if (q > 1) {
            q = q - 1
            // console.log(q)
        }
        setquan(q)
    }
    const addToNew = async (prodID) => {
        let { data } = await axios.post(`https://skincare-92zr.onrender.com/new/add/${prodID}`).catch(
            err => { console.log(err) }
        )
        if (data) {
            let res = await axios.put(`https://skincare-92zr.onrender.com/product/update/${prodID}`, { isNew: true }).catch(
                err => { console.log(err) }
            )
            // console.log(res.data.message.isNew)

            setNew(true)
            // console.log(data)
        }
    }

    const dltFromNew = async (prodID) => {
        let data = await axios.delete(`https://skincare-92zr.onrender.com/new/dlt/${prodID}`).catch(
            err => { console.log(err) }
        )
        if (data) {
            let res = await axios.put(`https://skincare-92zr.onrender.com/product/update/${prodID}`, { isNew: false }).catch(
                err => { console.log(err) }
            )
            setNew(false)
            // console.log(data)
        }
    }
    const addToWishList = async (userid, prodid) => {
        let { data } = await axios.patch(`https://skincare-92zr.onrender.com/user/addtowishlist/${userid}/${prodid}`)
        console.log(data)
        if (data) {
            setwished(true)
        }
    }

    const addToCart = async (userid, prodid, quantity, price, close) => {

        setconn(false)

        setout(false)
        // console.log(quantity)
        if (!load) {
            setload(true)
            if (quantity > 0) {
                let { data } = await axios.get(`https://skincare-92zr.onrender.com/product/by/${prodid}`).catch(err => console.log(err))
                let quant = data.message.quantity
                console.log(quant)
                if (quant > 0) {
                    let inf = { _id: prodid, quantity, price }
                    console.log("inff", inf)
                    console.log(userid)
                    await axios.patch(`https://skincare-92zr.onrender.com/user/addtocart/${userid}`, inf).then(
                        res => {
                            let diff = quant - quan
                            axios.put(`https://skincare-92zr.onrender.com/product/update/${prodid}`, { quantity: diff })
                                .then(res => { return setload(false) }).catch(err => { setload(false); return setconn(true) })
                                .catch(
                                    err => setconn(true)
                                )
                            return close()
                        }

                    ).catch(err => setconn(true))
                } else {
                    setout(true)
                }
            } else {
                setquanwarn(true)
            }
            setquan(1); setquanwarn(false); setload(false)
        }
    }
    const isWished = async (userid, prodid) => {
        let { data } = await axios.get(`https://skincare-92zr.onrender.com/user/by/${userid}`)
        let wishArray = data.message.wishList
        let is = wishArray.includes(prodid)
        is ? setwished(true) : setwished(false)
        // console.log(data.message.rate)
        const isProduct2Exist = data.message.rate.find(item => item.product === prodid);
        if (isProduct2Exist) {
            setRate(isProduct2Exist?.rate)
            // console.log(isProduct2Exist.rate)

        }
    }

    const isNew = async (prodid) => {
        let { data } = await axios.get(`https://skincare-92zr.onrender.com/product/by/${prodid}`)
        let is = data.message.isNew
        console.log(data.message.rating, data.message.rate.length)
        setrating({ rate: data.message.rating, num: data.message.rate.length })
        is ? setNew(true) : setNew(false)
    }
    useLayoutEffect(() => {
        if (id) {
            setid(id)
            isWished(id, props._id)
        }
        isNew(props._id)
    }, [])

    return (
        <div className='p-1 p-sm-3 col-xl-2 col-md-3 col-sm-6 col-xs-6 '>
            <div className='rounded rounded-3 col-12 card-bg d-flex flex-column  position-relative h-100 ' onClick={() => { navto(`/prod/${props._id}`) }}>

                {props.quantity > 0 ? <></> : <div className='position-absolute h-100 top-0 col-12 rounded rounded-3 out d-flex justify-content-center align-items-center' onClick={() => { navto(`/prod/${props._id}`) }}>
                    <p className='text-light fw-bold'>OUT OF STOCK</p>
                </div>}
                <div className='position-absolute w-100 d-flex justify-content-end p-3 dlt '>
                    {isAdmin ? <div>
                        <>{New ? <i class="fa-solid fa-circle-check fs-5 me-2 pink-text make-cursor icon" onClick={(e) => { e.stopPropagation(); dltFromNew(props._id) }}></i> : <i class="fa-solid fa-circle-plus fs-5 me-2 make-cursor icon" onClick={(e) => { e.stopPropagation(); addToNew(props._id) }}></i>}</>
                        <i class="fa-solid fa-pen-to-square me-2 fs-5" onClick={(e) => { e.stopPropagation(); navto(`/prodForm/update/${props._id}`) }}></i>
                        <Popup trigger=
                            {<i class="fa-solid fa-trash text-danger make-cursor fs-5" ></i>}
                            modal nested>
                            {
                                close => (


                                    <div className='alert alert-danger h-100'><p>you are about to delete this product</p>
                                        <div className='d-flex w-100 justify-content-center'>
                                            <button className='btn btn-danger me-2' id={props._id} onClick={(e) => { e.stopPropagation(); func(e); close() }}>Delete</button>
                                            <button className='btn btn-light' onClick={() => { close() }}>Cancel</button>
                                        </div>
                                    </div>


                                )
                            }
                        </Popup>


                    </div> : <></>}

                </div>
                <div className='col-12 d-flex justify-content-center align-items-center img-div'>
                    {props.images[0] ? <img src={props.images[(props.images.length) - 1].secure_url} alt="" className='col-12 product-img rounded rounded-3 h-100' /> :
                        <i class="fa-regular fa-image"></i>}
                </div>
                <div className=' px-2 p-sm-4 pt-sm-2 d-flex flex-column h-100 justify-content-start w-100 overflow-hidden '>
                    {props.newPrice ? <p className='mb-0 pink-text fs-6 fw-bold '><small className='p-1  price'>EGP {props.newPrice}</small></p> : <p className='mb-0 pink-text fs-6 '><small className='p-1  fw-bold price'>EGP {props.price}</small></p>}

                    <div className='d-flex justify-content-start align-items-center flex-wrap  w-100'>
                        {/* <div> */}
                        {/* <p className='fw-light m-0 brand'>brand</p> */}

                        {props.brand ? <p className='m-0'><span className='fs-xs-6 text-secondary fw-thin text-limit'><small>{brandname}</small></span></p> : <p className='m-0'><span className='fs-xs-6 text-secondary fw-light'><small>brand not specified </small></span></p>}
                        <p className=' fs-6 lh-1 make-cursor fw-thin col-12' onClick={() => { navto(`/prod/${props._id}`) }}><small className='fs-xs-6 col-12 text-limit '>{props.prodName}</small></p>
                        {/* </div> */}


                    </div>
                    <div className='d-flex flex-column justify-content-start h-100 '>

                        <div className='d-flex h-100 justify-content-end flex-column'>
                            <div className='mb-2' onClick={(e) => { e.stopPropagation() }}>
                                <div className='rate'>
                                    <Starrating onClick={(e) => { e.stopPropagation() }} prod={props._id} uid={userid} rate={rate} size={21} />
                                </div>
                            </div>
                            <div className='p-1 d-flex align-items-end justify-content-between flex-wrap'>
                                <div className=''>
                                    <Popup trigger={
                                        <i class="fa-solid fa-cart-shopping make-cursor icon me-2 fs-5 position-relative" ></i>}
                                        modal nested>
                                        {
                                            close => (

                                                <div className='alert alert-secondary h-100'>
                                                    {out ? <div className='alert alert-danger'>Sorry, Out Of Stock <div><button className='btn btn-danger' onClick={() => { setout(false) }}>Ok</button></div></div>
                                                        : <></>}
                                                    {Connection ?
                                                        <div className='alert alert-danger'>Sorry, Error in adding item in cart, Please check Internet Connection <div><button className='btn btn-danger' onClick={() => { setconn(false) }}>Ok</button></div></div>
                                                        : <></>
                                                    }
                                                    {warn ?
                                                        <div className='alert alert-danger'>Sorry, there is no more in store <div><button className='btn btn-danger' onClick={() => { setwarn(false) }}>Ok</button></div></div>
                                                        : <></>
                                                    }
                                                    {quantitywarn ?
                                                        <div className='alert alert-danger'>Please, Increase quantity <div><button className='btn btn-danger' onClick={() => { setquanwarn(false) }}>Ok</button></div></div>
                                                        : <></>
                                                    }
                                                    <p>{props.prodName}</p>
                                                    <div className='col-12 d-flex justify-content-center mb-2'>
                                                        <div className='d-flex'><button className='product-btn border  rounded rounded-2 px-3' onClick={dec}>-</button><span className='btn btn-white'>{quan}</span> <button className='logout-btn border rounded rounded-2 px-3' onClick={inc}>+</button></div>

                                                    </div>
                                                    <div className='d-flex w-100 justify-content-center'>
                                                        <button className='logout-btn rounded rounded-2  border me-2 d-flex justify-content-center align-items-center' id={props._id} onClick={(e) => { e.stopPropagation(); setload(true); addToCart(id, props._id, quan, props.newPrice, close); }}> {load ? <i class="fa-solid fa-spinner fa-spin white-text"></i> : <span className='white-text'>Add</span>}</button>
                                                        <button className='btn btn-light' onClick={() => { close(); setquan(1); setquanwarn(false) }}>Cancel</button>
                                                    </div>
                                                </div>


                                            )
                                        }
                                    </Popup>
                                    {wished ? <i class="fa-solid fa-heart-circle-check make-cursor icon fs-5 position-relative pink-text" onClick={(e) => { e.stopPropagation(); deleteWish(id, props._id); setwished(false) }}></i> : <i class="fa-solid fa-heart-circle-plus make-cursor icon fs-5 position-relative" onClick={(e) => {
                                        e.stopPropagation()
                                        // console.log(props.isNew)
                                        // console.log(id)
                                        addToWishList(id, props._id)
                                        setwished(true)
                                    }}></i>}
                                </div>
                                <div className='d-flex ps-2 '>
                                    <div className='me-1'>
                                        <i className="fa-regular fa-user price1 pink-text me-1 "></i>
                                        <span className='price1'>{rating?.num}</span>
                                    </div>
                                    <div >
                                        <i className="fa-solid fa-star price1 pink-text me-1"></i>
                                        <span className='price1'>{rating?.rate.toFixed(1)}</span>
                                    </div>


                                </div>
                                {/* <button className='product-btn col-12 rounded rounded-5 py-2 px-1 border border-1  d-flex align-items-center justify-content-around' onClick={() => { navto(`/prod/${props._id}`) }}>
                            <p className='mb-0 pink-text fs-6 ' ><small>show product</small></p>
                        </button> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
