import React, { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import Loading from './Loading'
import Wishitem from '../Components/Wishitem'
import { useEffect } from 'react'
export default function Wishlist() {
    let navto = useNavigate()
    let [data, setdata] = useState()
    let [user, setuser] = useState()
    
    const getMyData = async () => {
        let userData = localStorage.getItem('token')
        let { gmail, id } = jwtDecode(userData)
        setuser(id)
        // console.log(id)
        let { data } = await axios.get(`https://skincare-92zr.onrender.com/user/by/${id}`)
        console.log(data.message)
        let mywish = data.message.wishList
        setdata(mywish)
    }

    const deleteWish = async (userID, props) => {
        let { data } = await axios.patch(`https://skincare-92zr.onrender.com/user/dltfromwishlist/${userID}/${props}`).catch(
            err => {
                console.log(err)
            }
        )
       
        if (data) {
            // getMyData()
             console.log(data.message)
            let mywish = data.message.wishList
            // console.log(mywish)
            setdata(mywish)
            // console.log()
        }
        
    }

    useLayoutEffect(() => {
        getMyData()
    }, [])
    // console.log(data)

    return (
   
        <div className = 'container d-flex flex-wrap ' >
        {
            data? data.length ? <div className='container'>
                {
                    data.map((ele) => {
                        return <Wishitem props={ele} key={ele} userID={user} deleteWish={deleteWish} />
                    })
                }
            </div> : <div className='pt-5 w-100'><div className='w-100 h-100 d-flex justify-content-center align-items-center fw-bold fs-3 alert alert-secondary'>No Items In WishList</div></div> : < Loading />}


        </div >
    )
}
