import axios from 'axios'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from './Loading'
import twiter from '../images/icons8-twitter-48.png'
import facebook from '../images/icons8-facebook-48.png'
import whats from '../images/icons8-whatsapp-48.png'
import messanger from '../images/icons8-facebook-messenger-48.png'
import instagram from '../images/icons8-instagram-32.png'
import Carousel from 'react-bootstrap/Carousel';
import jwtDecode from 'jwt-decode';
import Starrating from '../Components/Starrating'
export default function Productdetails() {
  let { id } = useParams()
  let [prod, setprod] = useState({})
  let [user, setuser] = useState()
  let [userload, setuserload] = useState(true)

  let [url, seturl] = useState()
  let [wished, setWished] = useState(false)
  let [quantitywarn, setquanwarn] = useState(false)
  let [rating, setrating] = useState()
  let [rate, setRate] = useState()
  let [Connection, setconn] = useState(false)
  let [load, setload] = useState(false)
  let [warn, setwarn] = useState(false)
  let [quan, setquan] = useState(1)
  let [login, setlogin] = useState(false)
  const inc = () => {

    let q = quan
    q = q + 1
    if (q <= prod.quantity) {
      // console.log(prod.quantity)
      setquan(q)
    }
    else {
      setwarn(true)
    }
  }
  const dec = () => {
    setwarn(false)
    let q = quan
    if (q > 1) {
      q = q - 1
      // console.log(q)
    }
    setquan(q)
  }
  const deleteWish = async (userID, props) => {
    let { data } = await axios.patch(`https://skincare-92zr.onrender.com/user/dltfromwishlist/${userID}/${props}`).catch(
      err => {
        console.log(err)
      }
    )
    if (data) {
      setWished(false)
    }
    // console.log(data)

  }

  const addToCart = async (userid, prodid, quantity, price) => {

    setconn(false)
    setload(true)
    // console.log(quantity)
    if (quantity > 0) {
      let inf = { _id: prodid, quantity, price }
      console.log(userid, inf, prodid)
      await axios.patch(`https://skincare-92zr.onrender.com/user/addtocart/${userid}`, inf).then(
        res => {
          let diff = prod.quantity - quan
          axios.put(`https://skincare-92zr.onrender.com/product/update/${prodid}`, { quantity: diff })
            .then(res => { return setload(false) }).catch(err => { setload(false); return setconn(true) })
            .catch(
              err => { console.log(err); setconn(true) }
            )
          return
        }

      ).catch(err => { console.log(err); setconn(true) })

    } else {
      setquanwarn(true)
    }
    setquan(1); setquanwarn(false); setload(false)
    // navto('/cart')
  }
  const addToWishList = async (userid, prodid) => {
    let { data } = await axios.patch(`https://skincare-92zr.onrender.com/user/addtowishlist/${userid}/${prodid}`)
    console.log(data)
    if (data) {
      setWished(true)
    }
  }

  const userData = async () => {
    let user = localStorage.getItem('token')
    if (user) {
      let { id } = jwtDecode(user)
      setlogin(true)
      let { data } = await axios.get(`https://skincare-92zr.onrender.com/user/by/${id}`)
      const isProduct2Exist = data.message.rate.find(item => item.product === prod._id);
      if (isProduct2Exist) {
        setRate(isProduct2Exist?.rate)
      }
      setuser(data.message)
      if (data.message.wishList.includes(prod._id)) {
        setWished(true)
      } else {
        setWished(false)
      }
      setuserload(false)
    } else {
      setuserload(false)
      setlogin(false)
    }
  }

  function shareOnFacebook(url) {
    var currentUrl = encodeURIComponent(url);
    var shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl;
    console.log('facebook')
    window.open(shareUrl, '_blank');
  }

  function shareOnMessenger(url) {
    var currentUrl = encodeURIComponent(url);
    var shareUrl = 'fb-messenger://share/?link=' + currentUrl;
    console.log('messanger')

    window.open(shareUrl, '_blank');
  }
  function shareOnWhatsApp(url) {
    var currentUrl = encodeURIComponent(url);
    var shareUrl = 'whatsapp://send?text=' + currentUrl;
    console.log('whats')

    window.open(shareUrl, '_blank');
  }
  function shareOnTwitter() {
    var currentTitle = encodeURIComponent(document.title);
    var currentUrl = encodeURIComponent(window.location.href);
    var shareUrl = 'https://twitter.com/intent/tweet?text=' + currentTitle + '&url=' + currentUrl;
    window.open(shareUrl, '_blank');
  }
  function shareOnInstagram() {
    var currentUrl = encodeURIComponent(window.location.href);
    var shareUrl = 'https://www.instagram.com/sharer/sharer.php?u=' + currentUrl;
    window.open(shareUrl, '_blank');
  }

  const getProductData = async () => {
    let { data } = await axios.get(`https://skincare-92zr.onrender.com/product/by/${id}`)
    console.log(data.message.quantity)
    setrating({ rate: data.message.rating, num: data.message.rate.length })

    let newProd = { ...data.message }
    if (newProd.category == "Toners") {
      newProd["category"] = "Essence & Toner"
    } else if (newProd.category == "Cleansers") {
      newProd["category"] = "Mask & Cleanser"
    }
    setprod(newProd)
  }

  const [index, setIndex] = useState(0);

  useEffect(() => {
    getProductData()
    // shareProduct()
    var currentUrl = window.location.href
    seturl(currentUrl)
    console.log(wished)
  }, [])

  useEffect(() => {
    userData()
  }, [prod])

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  let navto = useNavigate()
  return (<div className=''>
    {userload ? <div className='position-fixed top-0 start-0 h-100 col-12 plur d-flex justify-content-center align-items-center'>
      <i class="fa-solid fa-spinner fa-spin white-text"></i>
    </div> : <></>}
    {Object.keys(prod).length != 0 ? <div className=' mt-4 h-100 container'>
      <div className='d-flex flex-wrap p-3 co'>
        <div className='col-12 mb-4'>
          <h1 className='pink-text fs-3'>{prod.prodName}</h1>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-4 p-3 '>
          <div className='col-12 border'>
            {prod.images[0] ?
              <Carousel activeIndex={index} onSelect={handleSelect} className='h-100'>
                {
                  prod.images.map(ele => {
                    return <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={ele.secure_url}
                        alt="First slide"
                      />
                    </Carousel.Item>


                  })
                }
              </Carousel>
              : <Loading />}

          </div>

          {url ? <div className='d-flex col-md-6 mt-2'>
            <div className='col-2 p-2 p-md-0 '>
              <img src={whats} alt="" onClick={() => { shareOnWhatsApp(url) }} className='col-12' />
            </div>
            <div className='col-2 p-2 p-md-0 '>
              <img src={messanger} alt="" onClick={() => { shareOnMessenger(url) }} className='col-12' />
            </div>
            <div className='col-2 p-2 p-md-0 ' >
              <img src={facebook} alt="" onClick={() => { shareOnFacebook(url) }} className='col-12' />
            </div>
            <div className='col-2 p-2 p-md-0 ' >
              <img src={twiter} alt="" onClick={() => { shareOnTwitter() }} className='col-12' />
            </div>
            <div className='col-2 p-2 p-md-0 ' >
              <img src={instagram} alt="" onClick={() => { shareOnInstagram() }} className='col-12' />
            </div>
          </div> : <></>}

          <div className='rate col-12 mt-2 d-flex flex-column align-items-center'>
            <div>
              {login && user ? <Starrating onClick={(e) => { e.stopPropagation() }} prod={id} uid={user._id} rate={rate} size={25} />
                :
                <Starrating onClick={(e) => { e.stopPropagation() }} prod={undefined} uid={undefined} rate={0} size={25} />
              }</div>
            <div className='d-flex ps-2 '>
              <div className='me-1'>
                <i className="fa-regular fa-user price1 pink-text me-1 "></i>
                <span className='price1'>{rating?.num}</span>
              </div>
              <div >
                <i className="fa-solid fa-star price1 pink-text me-1"></i>
                <span className='price1'>{rating?.rate.toFixed(1)}</span>
              </div>


            </div>

          </div>

        </div>
        <div className='col-sm-12 col-md-6 pt-1 ps-3 mt-2'>

          {login ? wished ?
            <button className='logout-btn rounded rounded-2 border p-2 price ' onClick={() => { deleteWish(user._id, prod._id) }}>
              <span className='white-text me-2  '>Delete from WishList</span>
              <i className="fa-solid fa-heart-circle-check make-cursor icon  position-relative white-text"></i>
            </button>
            : <button className='product-btn rounded rounded-2 border p-2 price ' onClick={() => { addToWishList(user._id, prod._id) }}><span className='pink-text me-2  '>Add to WishList</span>
              <i className="fa-solid fa-heart-circle-plus make-cursor icon  position-relative pink-text"></i>
            </button> : <button className='product-btn rounded rounded-2 border p-2 price ' onClick={() => { navto('/login') }}><span className='pink-text me-2  '>Add to WishList</span>
            <i className="fa-solid fa-heart-circle-plus make-cursor icon  position-relative pink-text"></i>
          </button>}



          <div className='mt-2'>
            <h2 className='fs-6 pink-text'>Brand</h2>
            <p className='price make-cursor fw-light' onClick={() => { navto(`/all?brand=${prod.brand}`) }}>{prod.brand}</p>
          </div>
          <div>
            <h2 className='fs-6 pink-text'>Description</h2>
            <p className='price'>{prod.prodDescription}</p>
          </div>
          <div className='col-12'>
            <h2 className='fs-6 pink-text'>Price : <span className='price text-dark fw-light'>{prod.price} EGP</span></h2>
            <h2 className='fs-6 pink-text'>Category : <span className='price text-dark fw-light'>{prod.category}</span></h2>
          </div>
          <div className='col-12'>


          </div>
          {user ? <div className='card-bg p-1 mt-2 position-relative'>
            {prod.quantity ? <></> : <div className='position-absolute h-100 top-0 col-12 rounded rounded-3 out d-flex justify-content-center align-items-center' >
              <p className='text-light fw-bold'>OUT OF STOCK</p>
            </div>}

            {Connection ?
              <div className='alert alert-danger'>Sorry, Error in adding item in cart, Please check Internet Connection <div><button className='btn btn-danger' onClick={() => { setconn(false) }}>Ok</button></div></div>
              : <></>
            }
            {warn ?
              <div className='alert alert-danger'>Sorry, there is no more in store <div><button className='btn btn-danger' onClick={() => { setwarn(false) }}>Ok</button></div></div>
              : <></>
            }
            {quantitywarn ?
              <div className='alert alert-danger'>Please, Increase quantity <div><button className='btn btn-danger' onClick={() => { setquanwarn(false) }}>Ok</button></div></div>
              : <></>
            }
            <p className='pink-text fw-thin'>Add to cart</p>

            {/* <div className='col-12 d-flex justify-content-center mb-2'> */}


            <div className='d-flex'> <p className='m-0 me-1'>Quantity : </p> <button className='product-btn border  rounded rounded-2 px-3' onClick={dec}>-</button><span className='btn btn-white'>{quan}</span> <button className='logout-btn border rounded rounded-2 px-3' onClick={inc}>+</button></div>

            {/* </div> */}
            <div className='d-flex w-100 justify-content-center mt-3 pb-2'>
              <button className='logout-btn rounded rounded-2  border me-2 d-flex justify-content-center align-items-center' id={prod._id} onClick={(e) => { e.stopPropagation(); setload(true); addToCart(user._id, prod._id, quan, prod.newPrice); }}> {load ? <i class="fa-solid fa-spinner fa-spin white-text"></i> : <span className='white-text'>Add</span>}</button>
              <button className='product-btn border rounded rounded-2 p-1 ' onClick={() => { setquan(1); setquanwarn(false) }}>Cancel</button>
            </div>
          </div> :
            <div className='card-bg p-1 mt-2 position-relative'>
              {prod.quantity ? <></> : <div className='position-absolute h-100 top-0 col-12 rounded rounded-3 out d-flex justify-content-center align-items-center' >
                <p className='text-light fw-bold'>OUT OF STOCK</p>
              </div>}
              {Connection ?
                <div className='alert alert-danger'>Sorry, Error in adding item in cart, Please check Internet Connection <div><button className='btn btn-danger' onClick={() => { setconn(false) }}>Ok</button></div></div>
                : <></>
              }
              {warn ?
                <div className='alert alert-danger'>Sorry, there is no more in store <div><button className='btn btn-danger' onClick={() => { setwarn(false) }}>Ok</button></div></div>
                : <></>
              }
              {quantitywarn ?
                <div className='alert alert-danger'>Please, Increase quantity <div><button className='btn btn-danger' onClick={() => { setquanwarn(false) }}>Ok</button></div></div>
                : <></>
              }
              <p className='pink-text fw-thin'>Add to cart</p>

              {/* <div className='col-12 d-flex justify-content-center mb-2'> */}
              <div className='col-12'>

                {login ? <>

                  <div className='d-flex'> <p className='m-0 me-1'>Quantity : </p>
                    <button className='product-btn border  rounded rounded-2 px-3' onClick={dec}>-</button><span className='btn btn-white'>{quan}</span> <button className='logout-btn border rounded rounded-2 px-3' onClick={inc}>+</button></div>
                  <div className='d-flex w-100 justify-content-center mt-3 pb-2'>
                    <button className='logout-btn rounded rounded-2  border me-2 d-flex justify-content-center align-items-center' id={prod._id} onClick={(e) => { e.stopPropagation(); setload(true); addToCart(user._id, prod._id, quan, prod.newPrice); }}> {load ? <i class="fa-solid fa-spinner fa-spin white-text"></i> : <span className='white-text'>Add</span>}</button>
                    <button className='product-btn border rounded rounded-2 p-1 ' onClick={() => { setquan(1); setquanwarn(false) }} >Cancel</button>
                  </div></> : <>
                  <div className='d-flex' onClick={() => { navto('/login') }}>
                    <p className='m-0 me-1'>Quantity : </p>
                    <button className='product-btn border  rounded rounded-2 px-3' onClick={() => { navto('/login') }}>-</button><span className='btn btn-white'>{quan}</span> <button className='logout-btn border rounded rounded-2 px-3' onClick={() => { navto('/login') }}>+</button></div>
                  <div className='d-flex w-100 justify-content-center mt-3 pb-2'>
                    <button className='logout-btn rounded rounded-2  border me-2 d-flex justify-content-center align-items-center' id={prod._id} onClick={() => { navto('/login') }}> {load ? <i class="fa-solid fa-spinner fa-spin white-text"></i> : <span className='white-text'>Add</span>}</button>
                    <button className='product-btn border rounded rounded-2 p-1 ' onClick={() => { navto('/login') }} >Cancel</button>
                  </div></>}</div>
            </div>
          }

        </div>
      </div>
    </div> : <div className='col-12 h-100 d-flex justify-content-center align-items-center'><Loading /></div>}</div>
  )
}
