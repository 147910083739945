import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Addproduct() {
  const navto = useNavigate()
  let [loading, setloading] = useState(false)
  let [errors, seterror] = useState()
  let [data, setdata] = useState({
    "category": "Cleansers"
  })
  const handleImages = (e) => {
    console.log(e.target.files)
    let newdata = { ...data }
    newdata[`${e.target.name}`] = e.target.files
    setdata(newdata)
    console.log(newdata)

  }
  const handleSubmition = (e) => {
    // let data = e.target.name
    let newdata = { ...data }
    newdata[`${e.target.name}`] = e.target.value

    setdata(newdata)
    console.log(newdata)
  }
  const submition = async (e) => {
    e.preventDefault()
    setloading(true)
    let formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);

    });
    const files = data.images;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i]);
      }
      for (var pair of formData.entries()) {
        console.log(pair[0] + ' - ' + pair[1]);
      }
    }

    let done = await axios.post("https://skincare-92zr.onrender.com/product/add", formData).catch(
      err => {
        // console.log()
        if (err.response.data.err.includes("prodName")) {
          console.log("product at least need name and price")
          seterror("product at least need name and price")
        } else {

          seterror("Please check if name contains any charecters like '$ % & * ...etc'")
        }
      }
    )
    setloading(false)
    console.log(done)
    if (done) {
      toast.success("product uploaded succesfully")
      e.target.reset()
      navto('/')
    }

  }
  return (

    <form action="" onSubmit={(e) => { submition(e) }} encType="multipart/form-data" className='border mt-3 p-2 container'>
      <p className='pink-text fs-2 fw-bold '>Add product</p>
      {errors ? <div className='alert alert-danger'>
        {errors}
      </div> : <></>}
      <div className='d-flex w-100 align-items-center'>
        <div className='p-1 w-100'>
          <label htmlFor="prodName" className='pink-text fw-bold'>product name *</label>
          <input type="text" placeholder='product name' name='prodName' id='prodName' className='form-control w-100' onChange={(e) => { handleSubmition(e) }} />
        </div>
        <div className='w-100'>
          <label htmlFor="price" className='pink-text fw-bold'>product price *</label>
          <input type="number" name="price" id="price" placeholder='Price' className='form-control w-100' onChange={(e) => { handleSubmition(e) }} />
        </div>


      </div>
      <div className='p-1'>
        <label htmlFor="prodDescription" className='pink-text fw-bold'>product description</label>
        <textarea name="prodDescription" id="prodDescription" cols="30" rows="10" placeholder='product description' className='form-control' onChange={(e) => { handleSubmition(e) }}></textarea>
      </div>
      <div className='p-1'>
        <label htmlFor="category" className='pink-text fw-bold'>Category:</label>

        <select name="category" id="category" className='form-control' onChange={(e) => { handleSubmition(e) }}>
          <option value="Cleansers">Mask & Cleanser</option>
          <option value="Toners">Essence & Toner</option>
          <option value="Serums">Serum</option>
          <option value="EyeCare">Eye Care</option>
          <option value="Moisturizers">Moisturizer</option>
          <option value="Sunscreens">Sunscreen</option>
          <option value="LipCare">Lip Care</option>
        </select>
      </div>
      <div className='p-1'>
        <label htmlFor="brand" className='pink-text fw-bold'>Brand:</label>

        <select name="brand" id="brand" className='form-control' onChange={(e) => { handleSubmition(e) }}>
          <option value="Abib">Abib</option>
          <option value="Axis_y">Axis-y</option>
          <option value="A.H.C">A.H.C</option>
          <option value="Anua">Anua</option>

          <option value="Beauty_of_joseon">Beauty Of joseon</option>
          <option value="BELLAMONSTER">BELLAMONSTER</option>
          <option value="B.LAB">B.LAB</option>

          <option value="COSNORI">COSNORI</option>
          <option value="Cosrx">Cosrx</option>

          <option value="Dr.Ceuracle">Dr.Ceuracle</option>
          <option value="DEWYTREE">DEWYTREE</option>

          <option value="esfolio">esfolio</option>

          <option value="Frudia">Frudia</option>

          <option value="Goodal">Goodal</option>

          <option value="Hada_Labo">Hada Labo</option>
          <option value="Haruharu">Haruharu</option>
          <option value="Holika_holika">Holika holika</option>
          <option value="heimish">heimish</option>

          <option value="innisfree">Innisfree</option>
          <option value="Im_form">Im form</option>
          <option value="IUNIK">IUNIK</option>
          <option value="Isntree">Isntree</option>

          <option value="Jumiso">Jumiso</option>

          <option value="Klairs">Klairs</option>
          <option value="kose">Kose</option>
          <option value="Klavuu">Klavuu</option>
          <option value="Ksecret">Ksecret</option>
          <option value="Kao-Biore">Kao-Biore</option>

          <option value="Manyo_factory">Manyo Factory</option>
          <option value="Mary_and_May">Mary and May</option>
          <option value="Missha">Missha</option>

          <option value="Numbuzin">Numbuzin</option>
          <option value="Neogen">Neogen</option>


          <option value="Purito">Purito</option>
          <option value="Peach_C">Peach C</option>
          <option value="Pyunkang_yul">Pyunkang yul</option>

          <option value="ROUND_LAB">ROUND LAB</option>
          <option value="Rovectin">Rovectin</option>

          <option value="SCINIC">SCINIC</option>
          <option value="Skinfood">Skinfood</option>
          <option value="SKIN1004">SKIN1004</option>
          <option value="SOME_BY_MI">SOME BY MI</option>

          <option value="Torriden">Torriden</option>
          <option value="THE_FACE_SHOP">THE FACE SHOP</option>
          <option value="Tosowoong">Tosowoong</option>
          <option value="TOCOBO">TOCOBO</option>

          <option value="VT_cometics">Vt cosmetics</option>

          <option value="Wishtrend">Wishtrend</option>



        </select>
      </div>
      <div className='p-1'>
        <div className='w-100'>
          <label htmlFor="quantity" className='pink-text fw-bold'>Quantity</label>
          <input type="number" name="quantity" id="quantity" placeholder='quantity' value={data.quantity} className='form-control w-100' onChange={(e) => { handleSubmition(e) }} />
        </div>
      </div>
      {/* <div className='p-1'>
        <label htmlFor="isAvailable" className='pink-text fw-bold'>Available:</label>
        <select name="isAvailable" id="isAvailable" className='form-control' value={data.quantity > 0 ? true : false} onChange={(e) => { handleSubmition(e) }}>
          <option value="true">Available</option>
          <option value="false">Out of stock</option>
        </select>
      </div> */}
      <div className='p-1'>
        <label htmlFor="images" className='pink-text fw-bold'>upload product images</label>
        <input type="file" name="images" id="images" multiple onChange={(e) => { handleImages(e) }} className='form-control' />
      </div>

      <div className='w-100 text-center p-1'><button className=' btn border product-btn ' onSubmit={(e) => { submition(e) }}> {loading ? <span><i class="fa-solid fa-spinner fa-spin pink-text"></i></span> : <span className=' pink-text'>add product</span>}</button></div>

    </form>
  )
}
