
import Popup from 'reactjs-popup';
import axios from 'axios';
import React, { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function Cartitem({ props, userID, deleteCartItem, order }) {
    let [data, setdata] = useState()
    let [quan, setquan] = useState()
    let [total, settotal] = useState()
    let [load, setload] = useState(false)
    let [one, setone] = useState(false)
    let [warn, setwarn] = useState(false)
    // console.log(props)
    let navto = useNavigate()
    const getProduct = async (prodid) => {
        if (prodid) {
            let data = await axios.get(`https://skincare-92zr.onrender.com/product/by/${prodid}`).catch(
                err => {
                    return console.log(err)
                }
            )
            if (data) {
                let prod = data.data.message
                setdata(prod)
                setquan(props.quantity)
                let t = prod.newPrice * props.quantity
                settotal(t)
            }

        }

        // console.log("productddd", prod.images['0'])
    }
    useLayoutEffect(() => {
        if (props.product) {
            getProduct(props.product)

        } else {
            getProduct(props._id)

        }
    }, [])
    const inc = () => {

        let d = { ...data }
        let q = quan
        q = q + 1
        console.log(q, props.quantity + data.quantity)
        if (q <= props.quantity + data.quantity) {
            let p = q * data.newPrice
            setquan(q)
            settotal(p)
        }
        else {
            setwarn(true)
        }
    }
    const dec = () => {

        let q = quan
        if (quan > 1) {
            q = q - 1
            setquan(q)
            let p = q * data.newPrice
            settotal(p)
        }
    }
    const saveChange = async (quan) => {
        if (!load) {
            setload(true)
            let d = { quantity: quan }
            await axios.patch(`https://skincare-92zr.onrender.com/user/updatecart/${userID}/${data._id}`, d).then(
                res => {
                    console.log(res)
                    let y = props.quantity - quan
                    let x = data.quantity + y
                    axios.put(`https://skincare-92zr.onrender.com/product/update/${props._id}`, { quantity: x }).then(res => { console.log("product", res) }).catch(
                        err => { console.log(err) }
                    )
                    setload(false)
                }
            ).catch(err => { console.log(err); setload(false) })
        }

    }
    useEffect(() => {
        if (one) {
            document.getElementById('dd').style.display='none'
        }
    }, [one])
    return (
        <div className='col-md-6 col-sm-12 col-xs-12 d-flex flex-column p-2   p-lg-3 fit '>
            {warn ? <div className='alert alert-danger'>No more in store <div><button className='btn btn-danger' onClick={() => { setwarn(false) }}>OK</button></div></div> : <></>}

            {
                data ? <div className='d-flex card-bg rounded rounded-3 justify-content-between col-12 h-100 position-relative pt-2' onClick={() => { navto(`/prod/${data._id}`) }}>
                    {order ?<></> : <Popup trigger=
                        {<div className='position-absolute top-0 end-0 text-danger dlt p-1 pe-2 fs-5' id='dd'>
                            <i class="fa-solid fa-xmark" id='spa'></i>
                        </div>}
                        modal nested>
                        {
                            close => (
                                <div className='w-100'>
                                    <div className='alert alert-danger h-100 fit-width'><p>Do you need to delete <span className='fw-bold'>{data.prodName}</span>  from Cart?</p>
                                        <div className='d-flex flex-wrap w-100 justify-content-center'>
                                            <button className='btn btn-danger m-1' onClick={(e) => { e.stopPropagation();  deleteCartItem(userID, data, quan);setone(true); close() }}>Delete</button>
                                            <button className='btn btn-light m-1' onClick={() => { close() }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Popup>}

                    <div className='h-100 col-12  position-absolute top-0 left-0  rounded rounded-3'>
                    </div>
                    <div className='d-flex h-100 d-flex align-items-center '>


                        <div className='col-4 col-lg-3 col-xs-5 me-2 h-100 p-2'>
                            <img src={data.images['0'].secure_url} className='col-12 rounded rounded-3 h-100' alt="" />
                        </div>
                        <div className=''>
                            <div className='m-0'><span className='text-limit '><small className='fw-thin price text-limit'>{data.prodName}</small></span></div>
                            <div><span className='text-secondary'> <small className='price'>{data.brand}</small></span></div>
                            <div><span className='price fw-bold'>EGP </span><span className='fw-thin price'>{data.price} * {quan} :<span className='fw-bold'> {total}</span> </span></div>

                            <div onClick={(e) => { e.stopPropagation() }}>

                                <div className='d-flex justify-content-between align-items-center flex-wrap col-12 position-relative dlt'>

                                    {order ? <p className='price'>quantity : {quan}</p> : <div onClick={(e) => { e.stopPropagation(); dec() }} className='py-2 d-flex'>
                                        <button className='product-btn rounded rounded-2 border dlt position-relative fs-xs-6'> <span className='pink-text fw-bold p-1'>-</span></button>
                                        <button className='btn btn-white fs-xs-6'>{quan}</button>
                                        <button className='product-btn rounded rounded-2 border dlt position-relative fs-xs-6' onClick={(e) => { e.stopPropagation(); inc() }}> <span className='pink-text fw-bold p-1'>+</span></button>
                                    </div>}

                                </div>
                                {order ? <></> : <div className='col-12 d-flex justify-content-center py-2 position-relative dlt'>
                                    <button className='logout-btn rounded rounded-2 border  ms-1 fs-xs-6 py-1' onClick={() => { saveChange(quan) }}><span className='white-text'>save </span>{load ? <i class="fa-solid fa-spinner fa-spin fs-xs-6 white-text"></i> : <i class="fa-solid fa-check fs-xs-6 white-text"></i>}</button>
                                </div>}

                            </div>

                        </div>

                    </div>

                    <div className='d-flex align-items-center p-3'>
                        <i class="fa-solid fa-chevron-right"></i>
                    </div>

                </div> : <></>
            }


        </div>
    )
}
