import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Productcard from '../Components/Productcard'
import Loading from './Loading';
import { data } from 'jquery';

export default function Newarrival({ isAdmin }) {
    // let [products, setProducts] = useState()
    const [productIds, setProductIds] = useState();
    const [products, setProducts] = useState();
  
    const fetchData = async () => {
        const { data } = await axios.get('https://skincare-92zr.onrender.com/new/all');
        setProductIds(data.message);
      };
    useEffect(() => {
      fetchData();
    }, []);
  
    useEffect(() => {
      const fetchProducts = async () => {
        if(productIds.length){
        const productData = await Promise.all(productIds.map(async (id) => {
            console.log(id)
            let d =id.prodID
          const res = await axios.get(`https://skincare-92zr.onrender.com/product/by/${d}`).catch(err=>console.log(err));
          if(res && res.data && res.data.message){
            console.log('elii byrg3', res.data.message)
            return res.data.message;
          }
        }));
        console.log(productData)
        setProducts(productData.reverse());
      };
    }
      if (productIds?.length > 0) {
        fetchProducts();
      }
    }, [productIds]);
    const deleteProduct = async (e) => {
        let dlt = await axios.delete(`https://skincare-92zr.onrender.com/product/dlt/${e.target.id}`).catch(
            err => {
                console.log(err)
            }
        )
        fetchData()
    }
    const deleteWish = async (userID, props) => {
        let { data } = await axios.patch(`https://skincare-92zr.onrender.com/user/dltfromwishlist/${userID}/${props}`).catch(
            err => {
                console.log(err)
            }
        )
        console.log(data)
        if (data) {
            fetchData()
        }
    }
    return(<div>
          <div className='d-flex justify-content-center align-items-center py-3 '>
            <div className='lineb'></div>
            <h2 className='fw-thin fs-6 mx-1 m-0'>New Arrivals</h2>
            <div className='lineb'>

            </div>
        </div>
        
        <div className="d-flex justify-content-start container flex-wrap">
          {products?products.length?products.map((product) => (
           <Productcard func={ deleteProduct} props={product} isAdmin={isAdmin} deleteWish={deleteWish}/>
          )):<div className='w-100 h-100 d-flex justify-content-center align-items-center fw-bold fs-3 alert alert-secondary'>No Available products</div>:<Loading/>}
        </div>
      </div>)
}