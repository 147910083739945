import React, { useLayoutEffect, useState } from 'react'

import Popup from 'reactjs-popup';
import axios from 'axios';
import Loading from '../Pages/Loading';
import { useNavigate } from 'react-router-dom';
export default function Wishitem({ props, userID, deleteWish }) {
    // console.log(props)
    let [data, setdata] = useState()
    let navto= useNavigate()
    const getProduct = async (prodid) => {
        let { data } = await axios.get(`https://skincare-92zr.onrender.com/product/by/${prodid}`).catch(
            err => {
                console.log(err)
            }
        )
        let prod = data.message
        setdata(prod)
        // console.log("productddd", prod.images['0'])
    }
    useLayoutEffect(() => {
        getProduct(props)
    }, [])
    return (
        <div className='col-md-6 col-sm-12 col-xs-12 d-flex  p-2  p-lg-3 fit '>
            {
                data ? <div className='d-flex card-bg rounded rounded-3 justify-content-between col-12 h-100 position-relative' onClick={()=>{navto(`/prod/${data._id}`)}}>
                    <Popup trigger=
                        {<div className='position-absolute top-0 end-0 text-danger dlt p-1 pe-2 fs-5'>
                            <i class="fa-solid fa-xmark"></i>
                        </div>}
                        modal nested>
                        {
                            close => (
                                <div className='w-100'>
                                    <div className='alert alert-danger h-100 fit-width'><p>Do you need to delete <span className='fw-bold'>{data.prodName}</span>  from Wishlist?</p>
                                        <div className='d-flex flex-wrap w-100 justify-content-center'>
                                            <button className='btn btn-danger m-1' onClick={(e) => { e.stopPropagation(); deleteWish(userID, props); close() }}>Delete</button>
                                            <button className='btn btn-light m-1' onClick={() => { close() }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Popup>

                    <div className='h-100 col-12  position-absolute top-0 left-0  rounded rounded-3'>
                    </div>
                    <div className='d-flex h-100 d-flex align-items-center'>
                        <div className='col-4 col-lg-3 col-xs-5 me-2 h-100 p-2'>
                            <img src={data.images['0'].secure_url} className='col-12 rounded rounded-3 h-100' alt="" />
                        </div>
                        <div className=''>
                            <div className='m-0'><span className='text-limit '><small className='fw-bold text-limit'>{data.prodName}</small></span></div>
                            <div><span className='text-secondary'> <small className='price'>{data.brand}</small></span></div>
                            <div><span className='price'>EGP </span><span className='fw-bold price'>{data.price}</span></div>

                        </div>
                    </div>

                    <div className='d-flex align-items-center p-3'>
                        <i class="fa-solid fa-chevron-right"></i>
                    </div>
                </div> : <></>
            }

        </div>
    )
}
