import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Signup() {
    const navto = useNavigate()
    let [data, setdata] = useState({})
    let [loading, setloading] = useState(false)
    let [errors, seterror] = useState()
    const handlesubmition = (e) => {
        let newData = { ...data }
        newData[e.target.name] = e.target.value
        setdata(newData)
    }

    const submit = async (e) => {
        e.preventDefault()
        setloading(true)
        let done = await axios.post("https://skincare-92zr.onrender.com/user/signup", data).catch(err => { console.log(err.response.data.err); 
        let d=err.response.data.err;
        
        if(d.includes("duplicate key")){
            seterror("Email already exits")
        }else if(d.includes("gmail")){
            seterror("Email is required")
        }
        else if(d.includes("password")){
            seterror("Password required")
        }
         })
         setloading(false)
        console.log(done)
        if(done){
            console.log(done)
            navto('/login')
        }
        
    }
    return (
        <form className='mt-5 p-3' onSubmit={(e) => { submit(e) }}>
            <h1 className='pink-text'>Register</h1>
            {errors?<div className='alert alert-danger'>
                {errors}
            </div>:<></>}
            <div className='d-flex flex-wrap justify-content-center'>
                <div className='col-md-6 col-sm-12 p-2'>
                    <label htmlFor="gmail">Email*</label>
                    <input type="email" name="gmail" id="gmail" onChange={(e) => { handlesubmition(e) }} placeholder='example@gmail.com' className='form-control' />
                </div>
                <div className='col-md-6 col-sm-12 p-2'>
                    <label htmlFor="password">Password*</label>
                    <input type="password" name="password" id="password" placeholder='Password' onChange={(e) => { handlesubmition(e) }} className='form-control' />
                </div>
            </div>
            <div className='d-flex flex-wrap justify-content-center'>
                <div className='col-md-6 col-sm-12 p-2'>
                    <label htmlFor="firstName">First name*</label>
                    <input type="text" name="firstName" id="firstName" placeholder='First name' onChange={(e) => { handlesubmition(e) }} className='form-control' />
                </div>
                <div className='col-md-6 col-sm-12 p-2'>
                    <label htmlFor="lastName">Last name</label>
                    <input type="text" name="lastName" id="lastName" placeholder="Last name" onChange={(e) => { handlesubmition(e) }} className='form-control' />
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-md-6 col-sm-12 p-2'>
                    <label htmlFor="mobileNo">Mobile number</label>
                    <input type="text" name="mobileNo" id="mobileNo" placeholder='01*********' onChange={(e) => { handlesubmition(e) }} className='form-control' />
                </div>
            </div>
            <div className='w-100 text-center'>
                <p>already have account? <span className='text-primary make-cursor' onClick={() => { navto('/login') }}>login</span></p>
            </div>
            <div className='w-100 text-center p-1'><button className=' btn border product-btn ' onSubmit={(e) => { submit(e) }}> {loading ? <span><i class="fa-solid fa-spinner fa-spin pink-text"></i></span> : <span className=' pink-text'>Register</span>}</button></div>

            {/* <div className='w-100 text-center p-1'><button className=' btn border product-btn '>  <span className=' pink-text'>Register</span></button></div> */}

        </form>
    )
}
