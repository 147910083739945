import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import img1 from '../images/Samar 4.PNG'
import img2 from '../images/Samar5.PNG'
import retnol from '../images/Samar8.PNG'
import somebyme from '../images/Samar3.PNG'
import img3 from '../images/Samar 6.PNG'
import img4 from '../images/Samar7.PNG'
import { useNavigate } from 'react-router-dom';
import eyecare from '../images/eye.jpeg'
import lip from '../images/lip.jpeg'
import clean from '../images/clean.jpeg'
import moist from '../images/mo.jpeg'
import sun from '../images/sun.jpeg'
import sur from '../images/ser.jpeg'
import ton from '../images/ton.jpeg'
import axios from 'axios';
import NewItem from '../Components/NewItem';
import shams from '../images/shmsya.jpeg'
import ndara from '../images/ndara.jpeg'
import eed from '../images/eed.jpeg'
export default function Main() {
    const [index, setIndex] = useState(0);
    const [New, setNew] = useState()
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    const navto = useNavigate()
    // const getData = async () => {
    //     let res = await axios.get(`https://skincare-92zr.onrender.com/new/all`).catch(err => { console.log(err) })
    //     let prods = res.data.message
    //     let arr = []
    //     prods.map(async (ele) => {
    //         let { data } = await axios.get(`https://skincare-92zr.onrender.com/product/by/${ele.prodID}`).catch(err => { console.log(err) })
    //         arr.push(data.message)
    //     })
    //     console.log(arr)
    //     setNew(arr)
    // }
    // useEffect(() => {
    //     if (!New) {
    //         getData()
    //     } else {
    //         console.log("w b3deeeeeen")
    //     }

    // }, [])
    // useEffect(() => {
    //     console.log("Changed New: ", New)
    // }, [New])
    return (
        <div className='col-12'>
            <div className=' vh-100 col-12'>

                <div className='caro col-12'>
                    <Carousel activeIndex={index} onSelect={handleSelect} className='h-100  col-12'>
                        <Carousel.Item className=' col-12 h-100 position-relative'>
                            <img
                                className="d-block col-12 h-100"
                                src={img1}
                                alt="First slide"
                            />
                            {/* <div className='position-absolute top-0 start-0 col-12 d-flex justify-content-center align-items-center'>
                                <div className='line'></div>  <h1 className='display-3 fw-thin text-light '>SKIN1004</h1><div className='line'></div>

                            </div> */}
                            <button onClick={() => { navto('/all?brand=Cosrx') }} className='position-absolute shopnow btn btn-outline-light fs-6 fs-xs-6 d-flex align-items-center '><span className='me-2'>SHOP NOW </span> <i class="fa-solid fa-arrow-right"></i></button>

                        </Carousel.Item>
                        <Carousel.Item className=' col-12 h-100 position-relative'>
                            <img
                                className="d-block col-12 h-100"
                                src={somebyme}
                                alt="First slide"
                            />
                            {/* <div className='position-absolute top-0 start-0 col-12 d-flex justify-content-center align-items-center'>
                                <div className='line'></div>  <h1 className='display-3 fw-thin text-light '>SKIN1004</h1><div className='line'></div>

                            </div> */}
                            <button onClick={() => { navto('/all?brand=SOME_BY_MI') }} className='position-absolute shopnow btn btn-outline-light fs-6 fs-xs-6 d-flex align-items-center '><span className='me-2'>SHOP NOW </span> <i class="fa-solid fa-arrow-right"></i></button>

                        </Carousel.Item>
                        <Carousel.Item className=' col-12 h-100 position-relative'>
                            <img
                                className="d-block col-12 h-100"
                                src={img2}
                                alt="First slide"
                            />
                            <button onClick={() => { navto('/all?brand=Beauty_of_joseon') }} className='position-absolute shopnow btn btn-outline-light fs-6 fs-xs-6  d-flex align-items-center '><span className='me-2'>SHOP NOW </span> <i class="fa-solid fa-arrow-right"></i></button>

                        </Carousel.Item>
                        <Carousel.Item className=' col-12 h-100 position-relative'>
                            <img
                                className="d-block col-12 h-100"
                                src={retnol}
                                alt="First slide"
                            />
                            <button onClick={() => { navto('/prod/64b07a6dc85d30b24b9d091b') }} className='position-absolute shopnow btn btn-outline-light fs-6 fs-xs-6  d-flex align-items-center '><span className='me-2'>SHOP NOW </span> <i class="fa-solid fa-arrow-right"></i></button>

                        </Carousel.Item>
                        <Carousel.Item className=' col-12 h-100 position-relative'>
                            <img
                                className="d-block col-12 h-100"
                                src={img3}
                                alt="First slide"
                            />
                            <button onClick={() => { navto('/all/?category=Sunscreens') }} className='position-absolute shopnow btn btn-outline-light   fs-6 fs-xs-6  d-flex align-items-center '><span className='me-2'>SHOP NOW </span> <i class="fa-solid fa-arrow-right" onClick={() => { navto('/all?brand=SOME_BY_MI') }}></i></button>
                        </Carousel.Item>

                    </Carousel>
                </div>

                <div className='col-12 mt-3 position-relative'>
                    <img src={img4} className='col-12'/>
                    <button onClick={() => { navto('/new') }} className='position-absolute shopnow btn btn-outline-light   fs-6 fs-xs-6  d-flex align-items-center '><span className='me-2'>SHOP NOW </span> <i class="fa-solid fa-arrow-right" onClick={() => { navto('/all?brand=SOME_BY_MI') }}></i></button>

                </div>

                <div className=' container mt-3'>
                    {/* <div className='d-flex justify-content-center align-items-center py-3 '>
                        <div className='lineb'></div>
                        <h2 className='fw-thin fs-4  m-0'>New Arrival</h2>
                        <div className='lineb'>

                        </div>
                    </div> */}
                    <div className='d-flex justify-content-start flex-wrap align-items-stretch'>
                        {/* {New ? New.map(ele => {
                           return <NewItem props={ele} key={ele._id} />
                        }) : <>Loading</>} */}

                    </div>
                    <div className='d-flex justify-content-center align-items-center py-3 '>
                        <div className='lineb'></div>
                        <h2 className='fw-thin fs-4  m-0'>Categories</h2>
                        <div className='lineb'>

                        </div>
                    </div>

                    <div className='d-flex justify-content-start flex-wrap align-items-stretch'>
                        <div className='col-lg-2 col-md-3 col-sm-6 col-xs-6 p-3 h-100 make-cursor categ' onClick={() => { navto('/all/?category=Toners') }}>
                            <div className='col-12 p-xl-2 p-1'>
                                <img src={ton} alt="" className='col-12' />
                                <div className='fs-6 w-100 text-center fw-thin'>
                                    Essence & Toner
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-6 col-xs-6 p-3 h-100 make-cursor categ' onClick={() => { navto('/all/?category=Moisturizers') }}>
                            <div className='col-12 p-xl-2 p-1'>
                                <img src={moist} alt="" className='col-12' />
                                <div className='fs-6 w-100 text-center fw-thin'>
                                    Moistrizer
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-6 col-xs-6 p-3 h-100 make-cursor categ' onClick={() => { navto('/all/?category=EyeCare') }}>
                            <div className='col-12 p-xl-2 p-1'>
                                <img src={eyecare} alt="" className='col-12' />
                                <div className='fs-6 w-100 text-center fw-thin'>
                                    Eye Care
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-6 col-xs-6 p-3 h-100 make-cursor categ' onClick={() => { navto('/all/?category=Serums') }}>
                            <div className='col-12 p-xl-2 p-1'>
                                <img src={sur} alt="" className='col-12' />
                                <div className='fs-6 w-100 text-center fw-thin'>
                                    Serum
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-6 col-xs-6 p-3 h-100 make-cursor categ' onClick={() => { navto('/all/?category=LipCare') }}>
                            <div className='col-12 p-xl-2 p-1'>
                                <img src={lip} alt="" className='col-12' />
                                <div className='fs-6 w-100 text-center fw-thin'>
                                    Lip Care
                                </div>
                            </div>
                        </div>



                        <div className='col-lg-2 col-md-3 col-sm-6 col-xs-6 p-3 h-100 make-cursor categ' onClick={() => { navto('/all/?category=Sunscreens') }}>
                            <div className='col-12 p-xl-2 p-1'>
                                <img src={sun} alt="" className='col-12' />
                                <div className='fs-6 w-100 text-center fw-thin'>
                                    SunScreen
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-6 col-xs-6 p-3 h-100 make-cursor categ' onClick={() => { navto('/all/?category=Cleansers') }}>
                            <div className='col-12 p-xl-2 p-1'>
                                <img src={clean} alt="" className='col-12' />
                                <div className='fs-6 w-100 text-center fw-thin'>
                                    Mask & Cleanser
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column col-12'>
                        <p className='fw-thin fs-3 p-2 pb-0 m-0'><span className='k fw-bold'>R</span>eady for Summer ?</p>
                        <span className=' p-2 fw-thin fs-xs-6 '>Discover the ultimate sun protection with our premium selection of Korean sunscreens, expertly crafted to shield your skin from harmful UV rays while nourishing it with top-quality ingredients. Experience the pinnacle of Korean beauty innovation and achieve a flawless, radiant complexion with our unbeatable range of sunscreens.</span>
                        <a className='btn d-flex align-items-center' onClick={() => { navto('/all/?category=Sunscreens') }}><span className='fs-6 me-2'>Shop Now</span> <i class="fa-solid fa-arrow-right-long"></i> </a>

                        <div className='d-flex justify-content-between flex-wrap col-12'>

                            <div className=' col-sm-4 col-xs-12 p-2'>
                                <img src={shams} className='col-12' alt="" />
                            </div>
                            <div className=' col-sm-4 col-xs-12 p-2'>
                                <img src={eed} className='col-12' alt="" />
                            </div>
                            <div className=' col-sm-4 col-xs-12 p-2'>
                                <img src={ndara} className='col-12' alt="" />
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <a className='btn d-flex align-items-center' onClick={() => { navto('/all/?category=Sunscreens') }}><span className='fs-6 me-2'>Shop Now</span> <i class="fa-solid fa-arrow-right-long"></i> </a>
                        </div>
                    </div>
                    

                </div>
                <div className='d-flex  col-12 foot text-white p-1 mt-3'>
            <div className='col-md-4 col-sm-6 col-xs-12 d-flex flex-column'>
                        <div className='container'>
                            <span className='fs-6 fs-xs-6'>Contact US</span>
                            <div className='d-flex col-12 '>
                        <a class="fa-brands fa-instagram fs-4 p-2 text-decoration-none text-white" href='https://instagram.com/samar_korean_beauty_mall?igshid=MjEwN2IyYWYwYw=='></a>
                        <a class="fa-brands fa-square-facebook fs-4 p-2 text-decoration-none text-white" href='https://m.facebook.com/groups/1832617527033554/?ref=share_group_link'></a>
                        </div>
                        </div>

                    </div>
            </div>
            </div>


        </div>

    )
}
