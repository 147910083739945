import React from 'react'

export default function () {
  return (
    <div className='w-100 loading d-flex justify-content-center align-items-center pink-text display-1'>
        {/* <i class="fa-solid fa-spinner fa-spin fs-1 "></i>
         */}
         {/* <i class="fa-solid fa-spray-can-sparkles  fa-beat-fade"></i> */}
         <i class="fa-solid fa-bahai fa-spin"></i>
    </div>
  )
}
